@use "./../abstracts" as *;

$color: map-get($colors, "white");
$bg: map-get($colors, "seventh");

$bullet-b-color: map-get($colors, "white");
$bullet-bg: map-get($colors, "white");

.green-marketing-slider {
  .swiper {
    &-wrapper {
      border-radius: 1rem;
      overflow: hidden;
    }

    &-slide {
      height: auto;
      min-height: 500px;

      background-color: $bg;

      padding: 1.5rem;
      padding-bottom: 4rem;

      display: flex;
      align-items: flex-start;

      @include breakpoint-max-md {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }

      @include breakpoint-min-md {
        align-items: center;
        padding: 2.5rem;
      }

      &:not(.slide-bg) {
        color: $color;
      }

      &.slide-bg {
        --slide-bg: unset;

        background: {
          image: var(--slide-bg);
          repeat: no-repeat;
          position: 60%;
          size: cover;
        }

        @include breakpoint-min-md {
          background-position: 30%;
        }

        &:after {
          content: "";
          z-index: -1;

          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;

          height: 213px;

          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.35) 0%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }
    }

    &-pagination {
      bottom: 1.625rem;

      &-bullet {
        opacity: 1;

        width: 0.8125rem;
        height: 0.8125rem;

        background-color: transparent;
        border: 1px solid $bullet-b-color;

        &.active {
          background-color: $bullet-bg;
        }
      }
    }
  }
  .slide-inner-image {
    border-radius: 1rem;
    box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.25);

    &-btn {
      filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.25));
    }
  }
}

@use "./../../abstracts/breakpoints" as *;

.wizard {
  &-body {
    &-wrap {
      display: flex;
      flex-wrap: wrap;

      gap: 1.25rem;

      @include breakpoint-min-md {
        gap: 2.5rem;
      }
    }
  }

  &-bg {
    position: fixed;
    left: 0;
    bottom: -25%;

    z-index: -1;

    width: 100%;
    height: 100%;

    object-fit: contain;

    transition: opacity 0.2s ease-in-out;
  }
}

@use "./../../abstracts/mixin" as *;
@use "./../../abstracts/colors" as *;

// @ 20223-09-11
// ########## Style
$color: map-get($colors, "white");

.link.white-link,
.back.white-link {
  @include create-link($color: $color);

  &:disabled {
    opacity: 0.5;
  }
}

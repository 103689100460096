@use "./../abstracts" as *;

// Initial space
$spacer: 0.5;

$max-loop-count: 10; // Numero massimo per contatore loop
// Non superare il numero consentito di 10 per non generare troppe utility class

@if ($max-loop-count > 10) {
  @error "Variabile $max-loop-count maggiore di 10, questo genererebbe troppe utility class";
}

@for $i from 0 through $max-loop-count {
  // margin
  .mb-x-#{$i} {
    margin-block: #{calc($spacer * $i)}rem !important;
  }

  .mb-start-#{$i} {
    margin-block-start: #{calc($spacer * $i)}rem !important;
  }

  .mb-end-#{$i} {
    margin-block-end: #{calc($spacer * $i)}rem !important;
  }

  .mi-y-#{$i} {
    margin-inline: #{calc($spacer * $i)}rem !important;
  }

  .mi-start-#{$i} {
    margin-inline-start: #{calc($spacer * $i)}rem !important;
  }

  .mi-end-#{$i} {
    margin-inline-end: #{calc($spacer * $i)}rem !important;
  }

  // padding
  .pb-x-#{$i} {
    padding-block: #{calc($spacer * $i)}rem !important;
  }

  .pb-start-#{$i} {
    padding-block-start: #{calc($spacer * $i)}rem !important;
  }

  .pb-end-#{$i} {
    padding-block-end: #{calc($spacer * $i)}rem !important;
  }

  .pi-y-#{$i} {
    padding-inline: #{calc($spacer * $i)}rem !important;
  }

  .pi-start-#{$i} {
    padding-inline-start: #{calc($spacer * $i)}rem !important;
  }

  .pi-end-#{$i} {
    padding-inline-end: #{calc($spacer * $i)}rem !important;
  }

  // gap
  .c-gap-#{$i} {
    gap: #{calc($spacer * $i)}rem !important;
  }

  @each $media, $value in $media-queries {
    @media (min-width: $value) {
      // margin

      .mb-#{$media}-x-#{$i} {
        margin-block: #{calc($spacer * $i)}rem !important;
      }

      .mb-#{$media}-start-#{$i} {
        margin-block-start: #{calc($spacer * $i)}rem !important;
      }

      .mb-#{$media}-end-#{$i} {
        margin-block-end: #{calc($spacer * $i)}rem !important;
      }

      .mb-#{$media}-y-#{$i} {
        margin-inline: #{calc($spacer * $i)}rem !important;
      }

      .mi-#{$media}-start-#{$i} {
        margin-inline-start: #{calc($spacer * $i)}rem !important;
      }

      .mi-#{$media}-end-#{$i} {
        margin-inline-end: #{calc($spacer * $i)}rem !important;
      }

      // padding
      .pb-#{$media}-y-#{$i} {
        padding-block: #{calc($spacer * $i)}rem !important;
      }

      .pb-#{$media}-start-#{$i} {
        padding-block-start: #{calc($spacer * $i)}rem !important;
      }

      .pb-#{$media}-end-#{$i} {
        padding-block-end: #{calc($spacer * $i)}rem !important;
      }

      .pb-#{$media}-y-#{$i} {
        padding-inline: #{calc($spacer * $i)}rem !important;
      }

      .pi-#{$media}-start-#{$i} {
        padding-inline-start: #{calc($spacer * $i)}rem !important;
      }

      .pi-#{$media}-end-#{$i} {
        padding-inline-end: #{calc($spacer * $i)}rem !important;
      }

      // gap
      .c-gap-#{$media}-#{$i} {
        gap: #{calc($spacer * $i)}rem !important;
      }
    }
  }
}

@use "./../abstracts" as *;

section {
  margin-block: clamp(3.75rem, 2.6786rem + 5.3571vw, 7.5rem);

  &.section-inner {
    padding-block: clamp(3.75rem, 2.6786rem + 5.3571vw, 7.5rem);
    overflow: hidden;
  }

  .section-title {
    margin-bottom: clamp(1.875rem, 1.1607rem + 3.5714vw, 4.375rem);
  }
}

@use "./../abstracts/breakpoints" as *;

.services-grid {
  display: grid;
  gap: 2.5rem;
  grid-template-columns: 1fr;

  @include breakpoint-min-md {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint-min-lg {
    grid-template-columns: repeat(3, 1fr);
  }
}

@use "./../abstracts" as *;

$body-ff: $ff-2;
$body-bg: map-get($colors, "light");
$body-color: map-get($colors, "first");

$small-fs: map-get($fs, "9");

body {
  color: $body-color;
  background-color: $body-bg;

  font: {
    family: $body-ff;
  }
}

textarea {
  resize: none;

  &::placeholder {
    font-style: italic;
    font-weight: $light;
    color: #00000040;
  }
}



p{
  margin-bottom: 0;
  &:first-child{
    margin-top: 0;
  }
  & + *{
    margin-top: 1rem;
  }
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-color: map-get($colors, "white");
  opacity: 1;
}

b,
strong {
  font-weight: $bold;
}

video {
  max-width: 100%;
  height: auto;
  display: block;
}

small {
  font-size: $small-fs;
}

a {
  color: var(--primary);
  font-weight: $semi-bold;

  &:hover {
    color: var(--primary-dark);
  }
}

ul,
ol{
  &:not(.list-group){
    margin-bottom: 0;
    + *{
      margin-top: 1rem;
    }
    font: {
      size: map-get($fs, "7");
    }

    line-height: 1.2222222222222223em;
    li{
      margin-bottom: 1rem;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
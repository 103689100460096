@use "./../../abstracts/colors" as *;
@use "./../../abstracts/mixin" as *;

// @ 20223-09-01
// ########## Style
$color: map-get($colors, "first");
$b-color: map-get($colors, "first");
$b-size: 1px;

// ########## States
$hover-color: map-get($colors, "white");
$hover-bg: map-get($colors, "first");
$hover-b-color: map-get($colors, "first");

$active-color: map-get($colors, "white");
$active-bg: map-get($colors, "first");
$active-shadow: 0px 0px 28px -8px map-get($colors, "first");
$active-b-color: map-get($colors, "first");

// ########## Structure
.btn.btn-outline-secondary {
  @include create-btn(
    $outline: true,
    $color: $color,
    $b-size: $b-size,
    $b-color: $b-color,
    $hover-color: $hover-color,
    $hover-bg: $hover-bg,
    $hover-b-color: $hover-b-color,
    $active-color: $active-color,
    $active-bg: $active-bg,
    $active-shadow: $active-shadow,
    $active-b-color: $active-b-color
  );
}

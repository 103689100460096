@use "./../../abstracts/breakpoints" as *;

.wizard {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  height: 100%;

  @include breakpoint-min-md {
    gap: 3rem;
  }

  &-wrapper {
    min-height: 100vh;
    display: grid;

    .container-close,
    .row,
    .wizard-container {
      min-height: 100%;
    }

    .container-close {
      display: grid;
    }
  }
}

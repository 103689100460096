.text-infinite-loop {
  &-container {
    display: flex;
    justify-content: center;
    gap: 1.875rem;

    white-space: nowrap;
  }

  &-list {
    display: flex;
    align-items: center;

    animation: loop 40s infinite linear;
  }

  &-item {
    display: flex;
    align-items: center;
    gap: 1.875rem;
  }
}

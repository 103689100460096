@use "./../../abstracts/font/fs" as *;

ul {
  &.marker--checker {
    list-style: none;
    padding-left: 0;

    li {
      padding-left: 1.875rem; // image width + space

      background: {
        image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='Export' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M3.24779094,9.63774976 L7.09090254,14.269858 L18.3199942,0.373533339 C19.1606749,-0.565407514 20.4817445,0.436129396 19.8212097,1.50026236 L8.77226386,19.0897543 C7.9315832,20.2164834 6.79065944,20.3416755 5.82988155,19.2149465 L0.245360007,12.2667841 C-0.835515129,10.6392867 1.92672133,8.32323256 3.24779094,9.63774976 Z' id='Path' fill='%2356FFB8'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        position: center left;
        repeat: no-repeat;
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }

  // Size
  &.list {
    &--md {
      li {
        font-size: map-get($fs, "7");

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    &--spacing-mb {
      li:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}

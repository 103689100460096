@use "./../abstracts" as *;

$b-color: rgba(map-get($colors, "first"), 30%);
$shadow: unset;
$padding-y: 1.25rem;

$logo-width: 70%;
$logo-m-width: 250px;

.navbar {
  background-color: white;
  border-bottom: 1px solid $b-color;

  padding: {
    top: $padding-y;
    bottom: $padding-y;
  }

  box-shadow: $shadow;

  &.scroll-up {
    transform: translateY(0);
  }

  &.scroll-down {
    transform: translateY(-100%);
  }

  &-logo {
    width: $logo-width;
    max-width: $logo-m-width;
  }

  // Mobile Button open Menu
  &-toggler {
    padding: 0;
    border: 0;

    transition: transform 0.6s ease-in-out;

    &:not(.collapsed) {
      transform: rotate(405deg);
    }
  }

  // Expand on lg
  .navbar-nav {
    align-items: center;
    gap: 1rem;

    @include breakpoint-max-lg {
      margin-top: 2rem;
    }

    @include breakpoint-min-lg {
      gap: clamp(1rem, -3.4286rem + 7.1429vw, 3rem);
    }

    /* @include breakpoint-min-xl {
      gap: 3rem;
    } */

    .nav-item{
      margin-bottom: 0;
    }
  }

  // Open Mobile Menu
  &-backdrop {
    position: fixed;
    inset: 0;
    z-index: 10;

    background-color: black;
    opacity: 0;

    transition: opacity 5s ease-in-out;

    &.appearing {
      // backdrop-filter: blur(50px);
      opacity: 0.2;
    }

    &.leaving {
      // backdrop-filter: blur(0);
      opacity: 0;
    }
  }
}

@use "./../abstracts/colors" as *;
@use "./../abstracts/breakpoints" as *;

$border-color: map-get($colors, "first");
$light-border-color: map-get($colors, "white");

.form-check {
  margin-bottom: 0;
  padding-left: 2rem;

  .form-check-input {
    margin-left: -2rem;
  }

  &-input {
    &:focus {
      box-shadow: unset;
    }

    &[type="checkbox"] {
      background-color: transparent;
      border-radius: 0;
      border-color: $border-color;

      &:checked {
        --bs-form-check-bg-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjAwMDcgMTUuMTcwOUwxOS4xOTMxIDUuOTc4NTJMMjAuNjA3MyA3LjM5MjczTDEwLjAwMDcgMTcuOTk5M0wzLjYzNjcyIDExLjYzNTRMNS4wNTA5MyAxMC4yMjEyTDEwLjAwMDcgMTUuMTcwOVoiIGZpbGw9IiMyRDZBNUIiLz4KPC9zdmc+Cg==);
      }
    }
  }

  &.form-check--light {
    .form-check-input {
      &[type="checkbox"] {
        border-color: $light-border-color;

        &:checked {
          --bs-form-check-bg-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEwLjAwMDcgMTUuMTcwOUwxOS4xOTMxIDUuOTc4NTJMMjAuNjA3MyA3LjM5MjczTDEwLjAwMDcgMTcuOTk5M0wzLjYzNjcyIDExLjYzNTRMNS4wNTA5MyAxMC4yMjEyTDEwLjAwMDcgMTUuMTcwOVoiIGZpbGw9InJnYmEoMjU1LDI1NSwyNTUsMSkiPjwvcGF0aD48L3N2Zz4=);
        }
      }
    }
  }

  &-label {
    font-size: 0.8125rem;
  }

  &-btn {
    position: relative;
    padding-left: 0;

    .form-check-input {
      cursor: pointer;

      position: absolute;
      inset: 0;

      width: 100%;
      height: 100%;

      margin: 0;

      opacity: 0;

      @include breakpoint-max-md {
        + .btn {
          min-width: unset;
        }
      }

      &:hover {
        + .btn.btn-outline-primary {
          color: #2d6a5b;
          background-color: #3ddd99;
          border-color: #3ddd99;
        }
      }

      &:checked {
        + .btn.btn-outline-primary {
          color: #2d6a5b;
          background-color: #56ffb8;
        }
      }
    }
  }
}

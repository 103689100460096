@use "./../../abstracts/font/fw" as fw;

$number-fw: fw.$medium;

.wizard {
  &-number {
    font: {
      size: 1rem;
      weight: $number-fw;
    }

    margin-bottom: 1.25rem;
  }
}

@use "./../abstracts/colors" as *;

$border-color: map-get($colors, "white");
$color: map-get($colors, "white");

.form-select {
  --bs-form-select-bg-img: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSI3Ij48cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMjgxLjAxN2gxMmwtNiA2eiIvPjwvc3ZnPg==);
  background-size: 12px 6px;

  background-color: transparent;
  border-radius: 0;
  border: 0;

  color: $color;
  border-bottom: 1px solid $border-color;

  padding: {
    left: 0.625rem;
  }

  &:focus {
    box-shadow: unset;
    border-color: $border-color;
  }
}

@use "./../../abstracts/mixin" as *;
@use "./../../abstracts/font" as *;

.link {
  @include create-link(
    $arrow: true,
    $td: none,
    $ff: $ff-1,
    $fw: $medium,
    $flex: true,
    $gap: 0.625rem
  );
}

button.link {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.back{
  @include create-link(
    $back: true,
    $td: none,
    $ff: $ff-2,
    $fw: $medium,
    $flex: true,
    $gap: 0.625rem
  );
}

button.back {
  padding: 0;
  border: 0;
  background-color: transparent;
}

@use "./../abstracts/colors" as *;

$color-label: map-get($colors, "first");
$light-color-label: map-get($colors, "white");

.form-floating {
  > label,
  .form-control,
  .form-control:focus,
  .form-control:not(:placeholder-shown) {
    padding: {
      left: 0;
      right: 0;

      top: 0.875rem;
      bottom: 0.875rem;
    }
  }

  > label {
    color: $color-label !important;
    // color: white !important;
    border: 0;
    font-size: 1.125rem;

    opacity: 0.6;

    &:after {
      content: unset !important;
    }
  }

  > .form-control {
    height: unset;
    min-height: unset;

    &:focus,
    &:not(:placeholder-shown) {
      & ~ label {
        padding-top: 0;
        transform: scale(0.85) translateY(-1rem);
      }
    }
  }

  &.form-floating--light {
    > label {
      color: $light-color-label !important;
    }
  }
}

@use "./../abstracts/colors" as *;

$color: map-get($colors, "first");
$border-color: map-get($colors, "first");

$light-color: map-get($colors, "white");
$light-placeholder-color: rgba(map-get($colors, "white"), 60%);
$light-border-color: map-get($colors, "white");

textarea.form-control {
  color: $color;

  border: {
    width: 1px;
    style: solid;
    radius: 1rem;
  }

  min-height: 175px;

  padding: 1.25rem;

  transition: all 0.2s ease-in-out;

  &:focus {
    color: $color;
    border-color: $border-color;
    background-color: transparent;
  }

  &::placeholder {
    font-style: normal;
  }

  &.form-control--light {
    color: $light-color;

    &:focus {
      color: $light-color;
      border-color: $light-border-color;
    }

    &::placeholder {
      color: $light-placeholder-color;
    }
  }

  &[disabled] {
    background-color: transparent;
    opacity: 0.4;
  }
}

.textarea-label {
  margin-bottom: 1.25rem;
}

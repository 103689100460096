#group-list-image {
  .tab-content {
    max-width: 100%;
    width: 420px;
    height: auto;

    background-color: white;

    aspect-ratio: 1;
    overflow: hidden;

    border-radius: 1rem;
  }
}

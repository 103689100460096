@use "./../../abstracts/breakpoints" as *;

.wizard-items-list {
  display: grid;

  > * {
    grid-area: 1/1;
    z-index: 0;
  }

  margin: {
    top: auto;
    bottom: auto;
  }
}

.wizard-item {
  &:not(.active) {
    display: none;
  }
}

@use "./../abstracts" as *;

.single-article-hero {
  min-height: 100vh;
  position: relative;

  margin-block: 0;

  color: map-get($colors, "white");

  z-index: 0;

  &:before,
  &__bg {
    width: 100%;
    height: 100%;

    position: absolute;
    inset: 0;
  }

  &:before {
    content: "";

    z-index: -1;

    background-color: rgba(map-get($colors, "black"), 70%);
  }

  &__bg {
    object-fit: cover;
    object-position: center;

    z-index: -2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.625rem;
  }
}

@use "./../abstracts" as *;

// Hero
/* #hero .col-12 {
  @include breakpoint-max-sm {
    padding: {
      left: 0;
      right: 0;
    }
  }
} */

// Calcola preventivo background
#section-bg-square {
  background: {
    image: url("./../img/square-bg.jpeg");
    position: center;
    size: cover;
    repeat: no-repeat;
    attachment: fixed;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      -webkit-background-size: cover;
    }
  }
}

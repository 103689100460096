.energy-saving{
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  
  &-content{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background: #081613;
    opacity: 0;
    transition: opacity ease-in-out .3s;

    p{
      color: #2D6A5B;
      text-align: center;
      max-width: 550px;
    }

    img{
      opacity: .8;
    }
  }


  

  

  &-active{
    overflow: hidden;
    .energy-saving{
      opacity: 1;
      display: flex;
      z-index: 9999;
    }
  }
}

.inactivity{
  overflow: hidden;
  .energy-saving{
    display: block;
    &-content{
      &.active{
        opacity: 1;
      }
    }
  }
}

@use "./../abstracts" as *;

$br: 1rem;

$title-ff: $ff-1;
$title-fs: 2rem;

.price-list__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: clamp(1rem, 0.571rem + 2.143vw, 2.5rem);

  @include breakpoint-min-xl {
    grid-template-columns: minmax(auto, 307px) minmax(auto, 445px) minmax(
        auto,
        307px
      );
  }
}

.price-list {
  @include breakpoint-max-xl {
    &--left {
      order: 2;
    }

    &--center {
      order: 1;
    }

    &--right {
      order: 3;
    }
  }

  &__box {
    border-radius: $br;
    overflow: hidden;

    padding: 1.75rem 1.25rem;

    border: 1px solid rgba(map-get($colors, "third"), 60%);
    background-color: map-get($colors, "seventh");

    display: flex;
    flex-direction: column;
    gap: 1.75rem;

    &.price-list--focus {
      border: 2px solid map-get($colors, "third");
      background-color: map-get($colors, "eleventh");

      padding: 1.75rem 1.375rem;
      padding-right: 1.125rem;

      box-shadow: 5px 16px 25px 0 rgba(0, 0, 0, 0.5);

      transition: scale 0.2s ease-out;

      &:hover {
        scale: 1.02;
      }

      .price-list__title {
        font-size: 2.5rem;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;

    min-height: 291px;

    &--top,
    &--bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      height: 100%;
    }
  }

  &__title {
    font: {
      family: $title-ff;
      size: $title-fs;
    }

    line-height: 1;

    &--small {
      font-size: 0.625em;
    }
  }

  &__subtitle {
    display: block;

    font: {
      size: 1rem;
      family: $title-ff;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
}

// .price-list-box {
// &__left-col {
//   border-radius: $br;

//   .price-spacing {
//     gap: 2.5rem;
//   }
// }

// &__spacing {
//   padding: 1.75rem 1.25rem;

//   @include breakpoint-min-lg {
//     &.focus {

//     }
//   }
// }
// }

@use "./../abstracts/colors" as c;
@use "./../abstracts/font/fs" as fs;
@use "./../abstracts/font/ff" as *;
@use "./../abstracts/font/fw" as *;

// $fs: map-get(fs.$fs, "6");

.text-gradient {
  font: {
    size: clamp(1.25rem, 1.0357rem + 1.0714vw, 2rem);
    family: $ff-1;
  }

  text-transform: uppercase;
  line-height: normal;

  &.text-gradient {
    &--1 {
      --gradient-color: #{map-get(c.$colors, "fourth")};
    }

    &--2 {
      --gradient-color: #{map-get(c.$colors, "ninth")};
    }

    &--3 {
      --gradient-color: #{map-get(c.$colors, "fifth")};
    }
  }

  &__title {
    background: linear-gradient(
      90deg,
      #{var(--gradient-color)} 0%,
      rgba(255, 255, 255, 1) 100%
    );

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    font-weight: $bold;
  }

  &__subtitle {
    font: {
      size: 0.5em;
      family: $ff-2;
    }
  }
}

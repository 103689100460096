@use "./../../abstracts/font/fs" as *;

p {
  font: {
    size: map-get($fs, "7");
  }

  line-height: 1.2222222222222223em;

  &.p-normal {
    font-size: map-get($fs, "8");
    line-height: 1.25em;
  }
}

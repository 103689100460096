@use "./../abstracts/font/fs" as *;
@use "./../abstracts/colors" as *;

$color: map-get($colors, "first");
$color-white: map-get($colors, "white");

.list-group {
  &.list-group--line {
    .list-group-item {
      background-color: transparent;
      padding: 0;
      border: 0;

      &,
      &.active {
        color: inherit;
      }

      &:first-child {
        .list-group-item-label {
          padding-top: 0;
        }
      }

      .list-group-item-label {
        padding: 0.75rem 0;
      }

      &.list-group-item--md {
        .list-group-item-label {
          padding: clamp(0.75rem, 0.6071rem + 0.7143vw, 1.25rem) 0;
        }
      }

      // List Group Action
      &.list-group-item-action {
        font-size: clamp(1.125rem, 0.8214rem + 1.5179vw, 2.1875rem);

        transition: {
          duration: 0.2s;
          property: font-size;
          timing-function: ease-in-out;
        }

        &:not(.active) {
          font-size: clamp(1.125rem, 0.9107rem + 1.0714vw, 1.875rem);

          .list-group-item-label {
            opacity: 0.5;
          }

          &:hover {
            .list-group-item-label {
              opacity: 1;
            }
          }
        }
      }

      // List Group Label
      &-label {
        padding: clamp(0.5rem, 0.3571rem + 0.7143vw, 1rem) 0;

        transition: {
          duration: 0.4s;
          property: opacity;
          timing-function: ease-in-out;
        }

        border: {
          width: 0 0 1px 0;
          radius: 0;
          style: solid;
          color: currentColor;
        }
      }
    }
  }

  &.list-group--white {
    color: $color-white;
  }
}

@use "./../../abstracts/colors" as *;
@use "./../../abstracts/mixin" as *;

// @ 20223-09-01
// ########## Style
$color: map-get($colors, "first");
$bg: map-get($colors, "third");

// ########## States
$hover-color: map-get($colors, "first");
$hover-bg: map-get($colors, "second");

$active-color: map-get($colors, "white");
$active-bg: map-get($colors, "first");
$active-shadow: 0px 0px 28px -8px map-get($colors, "first");

// Active White
$white-active-color: map-get($colors, "first");
$white-active-bg: map-get($colors, "white");

.btn.btn-primary {
  @include create-btn(
    $color: $color,

    $bg: $bg,

    $hover-color: $hover-color,
    $hover-bg: $hover-bg,

    $active-color: $active-color,
    $active-bg: $active-bg,
    $active-shadow: $active-shadow
  );
  &.active-white {
    &:active {
      color: $white-active-color;
      background-color: $white-active-bg;
    }
  }
}

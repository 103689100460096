@use "./../abstracts" as *;

$svg-line-abundance: 10px;

.svg-text-container {
  position: relative;
  display: inline-block;

  .svg-line {
    @include breakpoint-max-md {
      display: none;
    }

    position: absolute;
    bottom: -30%;
    left: -$svg-line-abundance;
    right: -$svg-line-abundance;

    display: grid;
    place-items: center;

    z-index: -1;
  }
}

$max-height: 470px;
$radius: 1rem;

.parallax {
  &-img {
    overflow: hidden;
    max-height: $max-height;

    &.parallax-img--rounded {
      border-radius: $radius;
    }
  }
}

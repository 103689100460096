@use "./../../abstracts/font" as *;

.title {
  &-1 {
    font: {
      family: $ff-1;
      size: map-get($fs, "2");
      weight: $medium;
    }

    line-height: 1.0615384615384615em;
  }

  &-2 {
    font: {
      family: $ff-1;
      size: map-get($fs, "3");
      weight: $medium;
    }

    line-height: 1.14em;
  }

  &-3 {
    font: {
      family: $ff-1;
      size: map-get($fs, "4");
      weight: $medium;
    }

    line-height: 1.2954545454545454em;
  }

  &-4 {
    font: {
      size: map-get($fs, "5");
      weight: $regular;
    }

    line-height: 1.1428571428571428em;
  }

  &-5 {
    font: {
      family: $ff-1;
      size: map-get($fs, "6");
      weight: $regular;
    }

    line-height: 1.3333333333333333em;
  }

  &-6_5 {
    font: {
      family: $ff-1;
      size: map-get($fs, "6_5");
      weight: $regular;
    }

    line-height: 1.2272727272727273em;
  }
}

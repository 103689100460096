@use "./../abstracts" as *;

.card-absolute-bg {
  position: relative;

  .card-bg {
    position: absolute;
  }

  &#card-bg-services {
    .card-bg {
      z-index: -1;
      width: 80%;
      right: -55%;

      &.card-bg {
        &--1 {
          top: -10%;
        }
        &--2 {
          bottom: -10%;
        }
      }

      @include breakpoint-min-md {
        // bottom: -50%;
      }
    }
  }
}

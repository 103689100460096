@use "./../abstracts" as *;

@use "./../../../node_modules/bootstrap/scss/bootstrap-grid.scss" with (
  $grid-gutter-width: 2.5rem,
  $container-max-widths: (
    xxl: calc(1340px + 2.5rem),
  )
);

@mixin make-container($mWidth, $padding-x: 2.5rem) {
  max-width: calc($mWidth + calc($padding-x * 2));
  padding-right: calc($padding-x / 2);
  padding-left: calc($padding-x / 2);
  margin: {
    left: auto;
    right: auto;
  }
}

.container.container-close {
  @include breakpoint-min-lg {
    @include make-container(840px);
  }

  @include breakpoint-min-xl {
    @include make-container(1010px);
  }

  @include breakpoint-min-xxl {
    @include make-container(1100px);
  }
}

.row {
  &.gutter-x--md {
    --bs-gutter-x: 3.75rem;
  }
}

@use "./../abstracts" as *;

$pt: 2.75rem;
$pb: 1.5rem;

footer {
  padding-top: $pt;
  padding-bottom: $pb;

  .copyright {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;

    border-top: 1px solid rgba(map-get($colors, "white"), 60%);

    margin-top: 2.5rem;
    padding-block-start: 1.5rem;

    @include breakpoint-max-md {
      flex-direction: column;
    }

    &-list {
      padding: 0;
      margin: 0;

      list-style: none;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      @include breakpoint-min-md {
        flex-direction: row;
        gap: 3.125rem;
      }
    }

    &-item {
      font-size: 1rem;
      margin-top: 0;
      margin-bottom: 0 !important;
    }

    &-link {
      font-weight: $regular;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

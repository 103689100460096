@use "./../abstracts" as *;

// width custom
.w-custom {
  --width: 100%;
  width: var(--width) !important;
}

@each $media, $value in $media-queries {
  @media (min-width: $value) {
    .w-#{$media}-custom {
      --width-#{$media}: 100%;
      width: var(--width-#{#{$media}}) !important;
    }
  }
}

.min-lg-width {
  --min-width: unset;

  @include breakpoint-min-lg {
    min-width: var(--min-width) !important;
  }
}

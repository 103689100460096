.wizard {
  &-steps-line {
    display: flex;
    gap: 0.625rem;

    transition: opacity 0.2s ease-in-out;
  }

  &-line {
    width: 100%;
    height: 4px;

    transition: background-color 0.2s ease-in-out;

    background-color: rgba(white, 30%);

    &.active {
      background-color: white;
    }
  }
}

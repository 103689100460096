@use "./../abstracts/colors" as *;

$color: map-get($colors, "first");
$border-color: map-get($colors, "first");

$light-color: map-get($colors, "white");
$light-border-color: map-get($colors, "white");

.form-control {
  border: 0;
  border-bottom: 1px solid $border-color;
  border-radius: 0;

  padding: {
    left: 0;
    right: 0;
  }

  &,
  &:focus {
    color: $color;
    border-color: $border-color;
    background-color: transparent;

    box-shadow: unset;
  }

  &,
  &::placeholder {
    font-size: 1.125rem;
  }

  &:placeholder-shown {
    color: rgba($color, 60%);
  }

  &::placeholder {
    color: rgba($color, 60%);
  }

  &.form-control--light {
    &,
    &:focus {
      color: $light-color;
      border-color: $light-border-color;
    }

    &:placeholder-shown {
      color: rgba($light-color, 60%);
    }
  }
}

.modal {
  --bs-modal-border-radius: 1rem;
  --bs-modal-inner-border-radius: 1rem;

  --bs-modal-width: 1020px;

  &-header {
    border-bottom: 0;

    padding-left: clamp(1rem, -0.1429rem + 5.7143vw, 5rem);
    padding-right: clamp(4rem, 3.5429rem + 2.2857vw, 5rem);
    padding-top: clamp(1rem, 0.3929rem + 3.0357vw, 3.125rem);
    padding-bottom: 0;

    margin-bottom: clamp(2rem, 1.5357rem + 2.3214vw, 3.625rem);

    .btn-close {
      position: absolute;
      top: clamp(3rem, 3.4571rem + -2.2857vw, 2rem);
      right: clamp(2rem, 1.5429rem + 2.2857vw, 3rem);
    }
  }

  &-body {
    padding: clamp(1rem, -0.1429rem + 5.7143vw, 5rem);
    padding-top: 0;
    padding-bottom: clamp(1rem, 0.4821rem + 2.5893vw, 2.8125rem);
  }
}

body.modal-open {
  &:after {
    content: "";

    position: fixed;
    inset: 0;

    width: 100%;
    height: 100%;

    backdrop-filter: blur(4px);
  }
}

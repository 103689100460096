@use "./../abstracts" as *;

// --- General
$rounded: 1rem;
$b-width: 0;

// --- Themes
$theme-primary-color: map-get($colors, "white");
$theme-primary-bg: map-get($colors, "tenth");

$theme-secondary-color: map-get($colors, "white");
$theme-secondary-bg: map-get($colors, "fourth");

$theme-tertiary-color: map-get($colors, "first");
$theme-tertiary-bg: map-get($colors, "second");

$theme-quaternary-color: map-get($colors, "first");
$theme-quaternary-bg: map-get($colors, "white");

// Href
$href-hover-color: map-get($colors, "second");

// --- Card Body
$body-px: 1.5625rem;
$body-py: 1.5625rem;

$body-px-1: 1.25rem;
$body-py-1: 1.25rem;

$body-px-2: 1.875rem;
$body-py-2: 1.25rem;

$body-py-3: 1.75rem;
$body-px-3: 1.875rem;

// --- Card Second DEPRECATED
$card-2-bg: map-get($colors, "fourth");
$card-2-p: 1.25rem;

.card {
  overflow: hidden;
  position: relative;
  z-index: 0;

  border-width: $b-width;

  &[href] {
    text-decoration: none;
    font-weight: inherit;

    // transition-property: color;

    &:hover {
      color: $href-hover-color;
    }
  }

  &--center-grid {
    display: grid;
    place-items: center;
  }

  &--min-h {
    &-1,
    &-2 {
      min-height: 250px;
    }

    &-3 {
      min-height: 232px;
    }

    @include breakpoint-min-md {
      &-1 {
        min-height: 325px;
      }

      &-2 {
        min-height: 285px;
      }
    }

    @include breakpoint-min-lg {
      &-4 {
        min-height: 420px;
      }
    }
  }

  &-body {
    padding: $body-py $body-px;

    &.body {
      &-1 {
        padding: $body-py-1 $body-px-1;
      }

      &-2 {
        padding: $body-py-2 $body-px-2;
      }

      &-3 {
        padding: $body-py-3 $body-px-3;
      }
    }
  }

  &--rounded {
    border-radius: $rounded;
  }

  &--theme-primary {
    color: $theme-primary-color;
    background-color: $theme-primary-bg;
  }

  &--theme-secondary {
    color: $theme-secondary-color;
    background-color: $theme-secondary-bg;
  }

  &--theme-tertiary {
    color: $theme-tertiary-color;
    background-color: $theme-tertiary-bg;
  }

  &--theme-quaternary {
    color: $theme-quaternary-color;
    background-color: $theme-quaternary-bg;

    transition: {
      property: all;
      duration: 0.2s;
      timing-function: ease-in-out;
    }

    &:hover {
      box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
    }
  }

  &-cover {
    aspect-ratio: 420/192;
    object-fit: cover;

    border-radius: 1rem;
  }

  .card__bg {
    --card-bg-width: 40%;
    --card-bg-top: 0;
    --card-bg-right: unset;
    --card-bg-left: unset;
    --card-bg-transform: unset;

    width: var(--card-bg-width);
    height: 100%;

    position: absolute;
    z-index: -1;

    top: var(--card-bg-top);
    right: var(--card-bg-right);
    left: var(--card-bg-left);

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;

      transform: scale(var(--card-bg-transform));
    }
  }
}

@use "./../../abstracts/font" as *;
@use "./../../abstracts/mixin" as *;

// Layout
$min-width: 10.4375rem;
$min-height: 3.75rem;
$gap: 0.5rem;

// Padding
$pt: 0.5rem;
$pr: 1rem;
$pb: 0.5rem;
$pl: 1rem;

// Border
$b-size: 0;
$b-radius: 1rem;

// Font
$ff: $ff-1;
$fs: map-get($fs, "7");
$fw: $medium;

.btn {
  @include create-btn(
    $flex: true,
    $gap: $gap,
    $min-width: $min-width,
    $min-height: $min-height,
    $pt: $pt,
    $pr: $pr,
    $pb: $pb,
    $pl: $pl,
    $b-size: $b-size,
    $b-radius: $b-radius,
    $ff: $ff,
    $fs: $fs,
    $fw: $fw
  );
}

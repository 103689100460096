.magazine-slider {
  overflow: visible;

  .atropos {
    height: 100%;
  }

  .card,
  .atropos-inner {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;

    .card {
      height: 100%;

      &-fade{
        height: 100%;
      }
    }
  }
}
